import React, { lazy, ReactNode, FC } from 'react';
const Footer = lazy(() => import('../components/Footer'));
const Header = lazy(() => import('../components/Header'));

type LayoutProps = {
  children?: ReactNode;
};


const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className='c8Page justify-center w-full'>
      <Header />
      <div id="mainMenu" className="w-full mx-auto px-0 lg:w-full">
        <main>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};
export default Layout;

